import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import PatentValuationImg from "images/patent-valuation.svg";
import ShieldIconImage from "images/shield-icon-black.svg";
import TechnologyIcon from "images/technology-icon.svg";
import ServiceStartup from "images/service-startup.svg";
import ScientificContent from "images/scientific-content.svg";
// import TechnologyIcon from "images/technology-icon.svg";
// import TechnologyIcon from "images/technology-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)`text-3xl`;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/2 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-2 px-2 py-2 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
  cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Patent Protection",
      description: [
        "Report on freedom to operate.",
        "Prior art search report.",
        "Patent Drafting & Filing.",
        "Prosecution of patent applications.",
        "Patent Litigation.",
      ],
      url: "#",
    },
    {
      imageSrc: PatentValuationImg,
      title: "Patent Valuation",
      description: [
        "Patent valuation based on projected cash flow and market method.",
        "Guidance for patent valuation based on Intellectual Property regulations.",
      ],
      url: "#",
    },
    {
      imageSrc: TechnologyIcon,
      title: "Technology Transfer",
      description: [
        "Guidance for Technology Transfer/ Selling/Buying/Licensing.",
        "Agreements related to Technology Transfer/Selling/Buying/Licensing.",
      ],
      url: "#",
    },
    {
      imageSrc: ServiceStartup,
      title: "Services for Startups",
      description: [
        "Guidance for Startup India Initiatives.",
        "Patent Strategy Creation for Startups.",
        "Patent Valuation for Connecting Investors.",
        "Guidance on various funding options for Technology Projects.",
      ],
      url: "#",
    },
    {
      imageSrc: ScientificContent,
      title: "Scientific Content Writing",
      description: [
        "Background study report of Technology-Publications and Patent review.",
        "Scientific content writing.",
        "Manuscript Editing-Proof reading, Copy editing, Development editing.",
      ],
      url: "#",
    },
  ],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null,
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <ContentWithPaddingXl>
        {heading && <Heading>{heading}</Heading>}

        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <ul className="description">
                  {Array.isArray(card.description) ? (
                    card.description.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))
                  ) : (
                    <p>{card.description}</p>
                  )}
                </ul>
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};
