// import React from "react";
// import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import { Link } from "react-router-dom";

// import LogoImage from "../../images/logo.svg";
// import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
// import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
// // import { ReactComponent as Instagram } from "../../images/instagram-icon.svg";
// // import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";

// const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
// const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

// const Column = tw.div`md:w-1/5`;
// const WideColumn = tw(
//   Column
// )`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

// const ColumnHeading = tw.h5`font-bold`;

// const LinkList = tw.ul`mt-4 text-sm font-medium`;
// const LinkListItem = tw.li`mt-3 cursor-pointer`;
// const Links = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

// const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
// const LogoImg = tw.img`w-8`;
// const LogoText = tw.h5`font-Tangerine text-5xl font-black text-primary-500`;

// const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

// const SocialLinksContainer = tw.div`mt-4 `;
// const SocialLink = styled.a`
//   ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
//   svg {
//     ${tw`w-4 h-4`}
//   }
// `;

// export default ({ scrollToSection, homeRef, servicesRef, teamMembersRef }) => {
//   return (
//     <Container>
//       <FiveColumns>
//         <WideColumn>
//           <LogoContainer>
//             {/* <LogoImg src={LogoImage} /> */}
//             <LogoText>RamSmith & Associates</LogoText>
//           </LogoContainer>
//           <CompanyDescription>
//             1272, Vasant Kunj, Sector B1, New Delhi - 110070
//           </CompanyDescription>
//           <SocialLinksContainer>
//             <SocialLink href="https://facebook.com">
//               <FacebookIcon />
//             </SocialLink>
//             <SocialLink href="https://twitter.com">
//               <TwitterIcon />
//             </SocialLink>
//             <SocialLink href="https://youtube.com">
//               <YoutubeIcon />
//             </SocialLink>
//           </SocialLinksContainer>
//         </WideColumn>
//         <Column>
//           <ColumnHeading>Quick Links</ColumnHeading>
//           <LinkList>
//             <LinkListItem>
//               <Links as={Link} to="/">
//                 Home{" "}
//               </Links>
//             </LinkListItem>
//             <LinkListItem>
//               <Links as={Link} to="/about-us">
//                 About Us
//               </Links>
//             </LinkListItem>
//             <LinkListItem>
//               <Links as={Link} to="/team">
//                 Team Members
//               </Links>
//             </LinkListItem>
//             <LinkListItem>
//               <Links as={Link} to="/advisors">
//                 Advisors
//               </Links>
//             </LinkListItem>
//             <LinkListItem>
//               <Links as={Link} to="/contact-us">
//                 Contact Us
//               </Links>
//             </LinkListItem>
//             <LinkListItem>
//               <Links as={Link} to="/privacy-policy">
//                 Privacy Policy
//               </Links>
//             </LinkListItem>
//           </LinkList>
//         </Column>

//         <Column>
//           <ColumnHeading>Contact Us</ColumnHeading>
//           <LinkList>
//             <LinkListItem>
//               <Link href="#">contact@ramsmithassociates.com</Link>
//             </LinkListItem>
//             <LinkListItem>
//               <Link href="#">ramsmithassociates@gmail.com</Link>
//             </LinkListItem>
//             <LinkListItem>
//               <Link href="#">9936335561</Link>
//             </LinkListItem>
//           </LinkList>
//         </Column>
//       </FiveColumns>
//     </Container>
//   );
// };

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Link } from "react-router-dom";

import LogoImage from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-x.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin.svg";
import { ReactComponent as EmailIcon } from "../../images/email-icon.svg"; // Import your email icon here
import { ReactComponent as PhoneIcon } from "../../images/phone-icon.svg"; // Import your phone icon here
import { ReactComponent as LocationPinIcon } from "../../images/location-pin.svg"; // Import your phone icon here

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(
  Column
)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3 flex items-center cursor-pointer`;
const Links = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const Icon = styled.span`
  ${tw`mr-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`font-Tangerine text-5xl font-black text-primary-500`;

const CompanyDescription = tw.p`flex items-center mt-4 max-w-xs md:max-w-xl font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-400 text-gray-100 hover:bg-gray-500 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default ({ scrollToSection, homeRef, servicesRef, teamMembersRef }) => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            {/* <LogoImg src={LogoImage} /> */}
            <LogoText>RamSmith & Associates</LogoText>
          </LogoContainer>
          <CompanyDescription>
            <Icon>
              <LocationPinIcon />
            </Icon>
            1272, Vasant Kunj, Sector B1, New Delhi - 110070
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/ramsmith-associates">
              <LinkedinIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>

        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Links as={Link} to="/">
                Home{" "}
              </Links>
            </LinkListItem>
            <LinkListItem>
              <Links as={Link} to="/about-us">
                About Us
              </Links>
            </LinkListItem>
            <LinkListItem>
              <Links as={Link} to="/team">
                Team Members
              </Links>
            </LinkListItem>
            <LinkListItem>
              <Links as={Link} to="/advisors">
                Advisors
              </Links>
            </LinkListItem>
            <LinkListItem>
              <Links as={Link} to="/contact-us">
                Contact Us
              </Links>
            </LinkListItem>
            <LinkListItem>
              <Links as={Link} to="/privacy-policy">
                Privacy Policy
              </Links>
            </LinkListItem>
            <LinkListItem tw="animate-fastPulse">
              <Links as={Link} to="/model-question-paper">
                Model Question Paper
              </Links>
            </LinkListItem>
          </LinkList>
        </Column>

        <Column>
          <ColumnHeading>Contact Us</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Icon>
                <EmailIcon />
              </Icon>
              <Links href="mailto:contact@ramsmithassociates.com">
                contact@ramsmithassociates.com
              </Links>
            </LinkListItem>
            <LinkListItem>
              <Icon>
                <EmailIcon />
              </Icon>
              <Links href="mailto:ramsmithassociates@gmail.com">
                ramsmithassociates@gmail.com
              </Links>
            </LinkListItem>
            <LinkListItem>
              <Icon>
                <PhoneIcon />
              </Icon>
              <Links href="tel:8864859608">+91 8864859608</Links>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
