// // // import React from "react";
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
// // // import styled from "styled-components"; //eslint-disable-line
// // // import { css } from "styled-components/macro"; //eslint-disable-line
// // // import Header from "components/headers/dark.js";
// // // import Footer from "components/footers/SimpleFiveColumn";
// // // import MainFeature1 from "components/features/TwoColWithButton.js";
// // // // import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// // // // import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// // // import Features from "components/features/ThreeColSimple.js";
// // // // import Features from "components/features/ThreeColWithSideImage.js";
// // // import TeamCardGrid from "components/cards/ProfileAndDescription";

// // // import SupportIconImage from "images/support-icon.svg";
// // // import ShieldIconImage from "images/shield-icon.svg";
// // // import CustomerLoveIconImage from "images/simple-icon.svg";

// const PageWrapper = tw.div`min-h-screen text-white`; // Creates a black background and adjusts text color
// // // const Subheading = tw.span`uppercase tracking-wider text-sm`;

// // // export default () => {
// // //   return (
// // //     <PageWrapper>
// // //       <AnimationRevealPage>
// // //         <Header />
// // //         <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} />
// // //         <Footer />
// // //       </AnimationRevealPage>
// // //     </PageWrapper>
// // //   );
// // // };
// // import React from "react";
// // import TeamCard from "../components/cards/ProfileAndDescription";
// // import SmitaImage from "../images/Team/1-Smitha Puthucheri.jpg";
// // import AshwiniImage from "../images/Team/2-Ashwini Pai.jpg";
// // import JyotsanaImage from "../images/Team/3-Jyotsna.jpg";
// // import SaleeshImage from "../images/Team/4-Saleesh.jpg";
// // import RajagopalImage from "../images/Team/5-Rajagopal Subrahmanian.jpg";
// // import SumalathaImage from "../images/Team/6-Sumanlatha.jpg";
// // import KiranImage from "../images/Team/7-Kiran Mohan Das.jpg";
// // import AnanthuImage from "../images/Team/8-Ananthu C N.jpg";
// // import { ReactComponent as TwitterIcon } from "../images/twitter-icon.svg";
// // import { ReactComponent as LinkedinIcon } from "../images/linkedin-icon.svg";

// // const cards = [
// //   {
// //     imageSrc: SmitaImage,
// //     position: "Founder & Managing Director",
// //     name: "Dr. Smitha Puthucheri",
// //     description: `
// //       Registered Patent Agent<br />
// //       PhD in Materials Chemistry - Indian Institute of Technology Kanpur<br />
// //       Ex-Senior Project Coordinator at Technology Development Board (Department of Science & Technology) GOI.<br />
// //       Ex-Patent Trainee at Technology Information Forecasting and Assessment Council (Department of Science & Technology) GOI.<br /><br />

// //       <strong>Experience:</strong>
// //       <ul>
// //         <li>20 years in Research and Development in Chemistry/Materials Chemistry/DRDO projects</li>
// //         <li>5 years in Industrial Project Management</li>
// //         <li>7 years in Patent-related works and Patent Law Firm</li>
// //         <li>3 years in manuscript editing services</li>
// //         <li>40+ Scientific publications in National/International Journals</li>
// //         <li>2 Granted Patents</li>
// //       </ul>
// //     `,
// //     links: [
// //       { url: "https://twitter.com", icon: TwitterIcon },
// //       { url: "https://linkedin.com", icon: LinkedinIcon },
// //     ],
// //   },
// //   // Add other cards similarly
// // ];

// // const TeamCardsList = () => (
// //   <div>
// //     {cards.map((card, index) => (
// //       <TeamCard
// //         key={index}
// //         imageSrc={card.imageSrc}
// //         name={card.name}
// //         position={card.position}
// //         description={card.description}
// //         links={card.links}
// //       />
// //     ))}
// //   </div>
// // );

// // export default TeamCardsList;

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import Header from "components/headers/dark.js";
import Footer from "components/footers/SimpleFiveColumn";
import CardComponent from "../components/cards/AdvisorsProfile";
// import TeamCard from "../components/cards/ProfileAndDescription";
import SathyamurthyImage from "../images/Team/Sathyamurthy.jpg";
import TataNarasingaImage from "../images/Team/Tata Narasinga Rao.jpg";
import RameshDharmajiImage from "../images/Team/dharmaji.jpg";
import SanjeevKumarVarshneyImage from "../images/Team/S K Varshney.jpg";
import ManishImage from "../images/Team/Manish Paliwal.jpg";
import PrakalpaSharmaImage from "../images/Team/Prakalpa Sharma.jpg";
import { ReactComponent as TwitterIcon } from "../images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "../images/linkedin-icon.svg";

// // CSS styling to center the cards
const PageWrapper = tw.div`m-10`; // Centers the content vertically and horizontally
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const CardsContainer = tw.div`flex flex-wrap justify-center items-center gap-8 max-w-screen-lg`; // Centers cards in a responsive grid with a gap
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center mt-5`;
// const cards = [
//   {
//     imageSrc: TataNarasingaImage,
//     position: "Scientific Advisor",
//     name: "Dr. Tata Narasinga Rao",
//     description: `
//         Research Advisor & Adjunct Professor at IIT-Hyderabad<br />
//         Ex-Director, ARCI-Hyderabad<br /><br />
//         <strong>Recipient of:</strong><br />
//         <ul>
//           <li>Material Research Society of India (MRSI) Medal - 2009</li>
//           <li>Tokyo University of Science President Award - 2014</li>
//           <li>Academician of Asia Pacific Academy of Materials (APAM) - 2015</li>
//           <li>Technology Day National Award - 2016</li>
//           <li>Fellow of Telangana & AP Academy of Sciences - 2017</li>
//           <li>Bangalore India Nano Innovation Award - 2018</li>
//           <li>Materials Science Annual Prize - 2022 of MRSI</li>
//         </ul>
//         200+ Scientific Publications in National/International Journals<br />
//         Filed/granted 20 International and Indian patents, several of which have been translated to technological developments.
//       `,
//     links: [
//       {
//         url: "https://twitter.com",
//         icon: TwitterIcon,
//       },
//       {
//         url: "https://linkedin.com",
//         icon: LinkedinIcon,
//       },
//     ],
//   },
//   {
//     imageSrc: PrakalpaSharmaImage,
//     position: "Legal Advisor",
//     name: "Prakalpa Sharma",
//     description: `
//         Deputy Registrar (Legal), Indian Institute of Technology Kanpur<br />
//         Ex-Law Officer / Examiner of Trademarks & Geographical Indications, Office of CGPDTM, Ministry of Commerce & Industry, Government of India<br />
//         Ex-Legal Manager at Bharat Electronics Limited, Ministry of Defence U.T.<br /><br />
//         <strong>Credentials:</strong>
//         <ul>
//           <li>Enrolled as an Advocate with BAR Council of Delhi and Member Delhi Bar Association</li>
//           <li>Associate Member of the Indian Society of International Law, New Delhi</li>
//           <li>Associate Member of Indian Society for Training and Development</li>
//           <li>Resource Faculty of Commission for Scientific & Technical Terminology, Ministry of Education, New Delhi</li>
//           <li>Appointed as a Special Judicial Magistrate by UP Government on recommendations of the Hon'ble Allahabad High Court in 2021</li>
//         </ul>
//       `,
//     links: [
//       {
//         url: "https://twitter.com",
//         icon: TwitterIcon,
//       },
//       {
//         url: "https://linkedin.com",
//         icon: LinkedinIcon,
//       },
//     ],
//   },
//   {
//     imageSrc: RameshDharmajiImage,
//     position: "Financial Advisor",
//     name: "Ramesh Dharmaji",
//     description: `
//         Ex-CGM SIDBI<br />
//         Founding Director of SIDBI International MSME Training Institute<br />
//         A career banker with over 38 years of experience in promotion, development, and financing of MSMEs, product structuring, and delivery.<br /><br />
//         <strong>Roles and Associations:</strong>
//         <ul>
//           <li>Sr. Adviser to Global Alliance for Mass Entrepreneurship</li>
//           <li>Financial Expert for Technology Development Board</li>
//           <li>Advisor to a venture capital firm</li>
//           <li>Mentoring MSMEs in finance, strategy, etc.</li>
//         </ul>
//       `,
//     links: [
//       {
//         url: "https://twitter.com",
//         icon: TwitterIcon,
//       },
//       {
//         url: "https://linkedin.com",
//         icon: LinkedinIcon,
//       },
//     ],
//   },
// ];

// const TeamCardsList = () => (
//   <CardsContainer>
//     {cards.map((card, index) => (
//       <TeamCard
//         key={index}
//         imageSrc={card.imageSrc}
//         name={card.name}
//         position={card.position}
//         description={card.description}
//         links={card.links}
//       />
//     ))}
//   </CardsContainer>
// );

// export default () => {
//   return (
//     <AnimationRevealPage>
//       <Header />
//       <SectionHeading>Meet the Advisors</SectionHeading>
//       <PageWrapper>
//         <Subheading>Our Team</Subheading>
//         <TeamCardsList />
//       </PageWrapper>
//       <Footer />
//     </AnimationRevealPage>
//   );
// };

// import React from "react";
// import CardComponent from "../components/cards/AdvisorsProfile"; // Adjust path based on your file structure
// // import TataNarasingaImage from "./path/to/TataNarasingaImage.jpg";
// // import PrakalpaSharmaImage from "./path/to/PrakalpaSharmaImage.jpg";
// // import RameshDharmajiImage from "./path/to/RameshDharmajiImage.jpg";
// import TataNarasingaImage from "../images/Team/Tata Narasinga Rao.jpg";
// import RameshDharmajiImage from "../images/Team/dharmaji.jpg";
// import PrakalpaSharmaImage from "../images/Team/Prakalpa Sharma.jpg";
// // import { ReactComponent as TwitterIcon } from "./path/to/TwitterIcon.svg";
// // import { ReactComponent as LinkedinIcon } from "./path/to/LinkedinIcon.svg";
// import { ReactComponent as TwitterIcon } from "../images/twitter-icon.svg";
// import { ReactComponent as LinkedinIcon } from "../images/linkedin-icon.svg";

const cards = [
  {
    imageSrc: SathyamurthyImage,
    position: "Scientific Advisor",
    name: "Prof. N. Sathyamurthy",
    smallDescription: `
      Founder Director, Indian Institute of Science Education and Research (IISER) Mohali<br />
      Professor (Retired), IIT Kanpur
    `,
    bigDescription: `
    <ul>
    <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Prof. N. Sathyamurthy</h1>
        <li>Ex-Honorary Director Centre for Co-operation in Science & Technology among Developing Societies (CCSTDS) Chennai</li>
        <li>Fellow, Indian Academy of Sciences, Bangalore</li>
        <li>Fellow, Indian National Science Academy, New Delhi</li>
        <li>Fellow, Third World Academy of Sciences, Trieste, Italy</li>
        <li>Honorary Professor, JNCASR, Bangalore</li>
        <li>Honorary Professor, S. N. Bose National Center for Basic Sciences, Calcutta 1995-98</li>
        <li>S.S. Bhatnagar Prize in Chemical Sciences, CSIR</li>
        <li>Sir C.V. Raman Award, Hari Om Ashram Trust, University Grants Commission.</li>
        <li>J. C. Bose Fellow, Department of Science and Technology</li>
        <li>Editorial Board member of reputed National and International Journals</li>
        <li>225+ Scientific Research Publications</li>

      </ul>
    `,
  },
  {
    imageSrc: TataNarasingaImage,
    position: "Scientific Advisor",
    name: "Prof. Tata Narasinga Rao",
    smallDescription: `
      Research Advisor & Adjunct Professor at Indian Institute of Technology Hyderabad<br />
      Ex- Director, ARCI-Hyderabad
    `,
    bigDescription: `
    <ul>
    <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Prof. Tata Narasinga Rao</h1>
        <li>Material Research Society of India (MRSI) Medal-2009</li>
        <li>Tokyo University of Science President Award-2014</li>
        <li>Academician of Asia Pacific Academy of Materials (APAM)-2015</li>
        <li>Technology Day National Award-2016</li>
        <li>Fellow of Telangana & AP Academy of Sciences-2017</li>
        <li>Bangalore India Nano Innovation Award-2018</li>
        <li>Materials Science Annual Prize-2022 of MRSI</li>
        <li>200+ Scientific Publications in National/International Journals</li>
        <li>Filed/granted 20 International and Indian patents, several of which have been translated to technological developments</li>
      </ul>
    `,
  },
  {
    imageSrc: RameshDharmajiImage,
    position: "Financial Advisor",
    name: "Sh. Ramesh Dharmaji",
    smallDescription: `
      Ex-CGM SIDBI<br />
      Founder Director of SIDBI International MSME Training Institute
    `,
    bigDescription: `
    <ul>
    <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Sh. Ramesh Dharmaji</h1>
        <li>A career Banker with over 38 years of experience in Promotion, Development and Financing of MSMEs, Product structuring, and delivery</li>
        <li>Sr. Adviser to Global Alliance for Mass Entrepreneurship</li>
        <li>Financial Expert for Technology Development Board</li>
        <li>Advisor to a venture capital firm</li>
        <li>Mentoring MSMEs in finance, strategy, etc.</li>
        <li>Mentored several startups</li>
      </ul>
    `,
  },
  {
    imageSrc: SanjeevKumarVarshneyImage,
    position: "Scientific Advisor for International Cooperation",
    name: "Dr. Sanjeev Kumar Varshney",
    smallDescription: `
      Science facilitator and promoter<br />
      Ex-Scientist G & Head, International S&T Cooperation - Department of Science and Technology
    `,
    bigDescription: `
    <ul>
    <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Dr. Sanjeev Kumar Varshney</h1>
        <li>A Science promoter, facilitator & Science communicator</li>
        <li>Facilitated International Scientific cooperation for more than 33 years from the Department of Science & Technology (DST), Government of India</li>
        <li>Editor of PINSA, Member - Academic Council HS Gaur University, Research, and Innovation Council, JSS Science & Technology University</li>
        <li>Counsellor (Science and Technology), Embassy of India in Moscow</li>
      </ul>
    `,
  },
  {
    imageSrc: ManishImage,
    position: "Legal Advisor, Founder-Legaltick",
    name: "Sh. Manish Paliwal, FCIArb",
    smallDescription: `
      Advocate on Record in the Supreme Court of India<br />
      Insolvency Professional & a Fellow of the Chartered Institute of Arbitrators<br />
      Attorney-at- Intellectual Property Law
    `,
    bigDescription: `
    <ul>
      <h1 style="font-size: 16px; font-weight: bold; padding-bottom:10px; text-align:center;">Sh. Manish Paliwal</h1>
      <li>Extensive experience representing clients across the Indian judiciary - spanning the Supreme Court of India, the Delhi High Court, the National Company Law Tribunal (NCLT), and the National Company Law Appellate Tribunal (NCLAT)</li>
      <li>Expertise covers a wide range of legal areas, including:</li>
      <li><b>Corporate Law:</b> Advisory and representation on mergers, acquisitions, and governance.</li>
      <li><b>Bankruptcy & Insolvency:</b> Guidance through complex restructuring and resolution processes.</li>
      <li><b>Intellectual Property & Cyber Law:</b> Protection and enforcement of IP rights and digital compliance. Represented clients in patent opposition and litigation cases, providing thorough infringement analysis and strategic legal guidance.</li>
      <li><b>Administrative Law & Government Contracts:</b> Regulatory compliance and public sector contracting.</li>
      
    </ul>
  `,
  },
  // Add more advisor cards here if needed
];

const AdvisorsSection = () => (
  <CardComponent cards={cards} heading="" description="" />
);

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <SectionHeading>Our Advisors</SectionHeading>
      <PageWrapper>
        <Subheading></Subheading>
        <AdvisorsSection />
      </PageWrapper>
      <Footer />
    </AnimationRevealPage>
  );
};

// export default AdvisorsSection;
