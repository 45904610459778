import React from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";

const CardContainer = styled.div`
  ${tw`flex items-start p-6 bg-white shadow-lg rounded-lg max-w-4xl`}
  @media (max-width: 640px) {
    ${tw`flex-col items-center`}// Stack the image on top on small screens
  }
`;

const CardImageContainer = styled.div`
  ${tw`flex flex-col items-center mr-8`}
  @media (max-width: 640px) {
    ${tw`mr-0 mb-4`}// Remove margin-right and add bottom margin on small screens
  }
`;

const CardImage = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`w-64 h-64 bg-cover bg-center rounded`}
`;

const SocialMediaLinks = styled.div`
  ${tw`flex mt-4 space-x-4`}
`;

const IconLink = styled.a`
  ${tw`text-primary-500 hover:text-primary-700 transition duration-300`}
`;

const CardContent = styled.div`
  ${tw`flex flex-col`}
  @media (max-width: 640px) {
    ${tw`text-center`}// Center text on small screens
  }
`;

const Name = styled.h2`
  ${tw`text-2xl font-bold text-gray-900`}
`;

const Position = styled.h3`
  ${tw`uppercase font-bold tracking-wide text-primary-500 text-sm mt-1`}
`;

const DescriptionList = styled.div`
  ${tw`mt-4 text-gray-700`}
`;

const TeamCard = ({ imageSrc, name, position, description, links }) => (
  <CardContainer>
    <CardImageContainer>
      <CardImage imageSrc={imageSrc} />
      <SocialMediaLinks>
        {links.map((link, index) => (
          <IconLink
            key={index}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <link.icon />
          </IconLink>
        ))}
      </SocialMediaLinks>
    </CardImageContainer>

    <CardContent>
      <Name>{name}</Name>
      <Position>{position}</Position>
      <DescriptionList dangerouslySetInnerHTML={{ __html: description }} />
    </CardContent>
  </CardContainer>
);

export default TeamCard;
