// import React from "react";
// import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import {
//   SectionHeading,
//   Subheading as SubheadingBase,
// } from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import EmailIllustrationSrc from "images/email-illustration.svg";

// const Container = tw.div`relative`;
// const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
// const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
// const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
// const TextColumn = styled(Column)((props) => [
//   tw`md:w-7/12 mt-16 md:mt-0`,
//   props.textOnLeft
//     ? tw`md:mr-12 lg:mr-16 md:order-first`
//     : tw`md:ml-12 lg:ml-16 md:order-last`,
// ]);

// const Image = styled.div((props) => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded bg-contain bg-no-repeat bg-center h-full`,
// ]);
// const TextContent = tw.div`lg:py-8 text-center md:text-left`;

// const Subheading = tw(SubheadingBase)`text-center md:text-left`;
// const Heading = tw(
//   SectionHeading
// )`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
// const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

// const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
// const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
// const Textarea = styled(Input).attrs({ as: "textarea" })`
//   ${tw`h-24`}
// `;

// const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

// export default ({
//   subheading = "Contact Us",
//   heading = (
//     <>
//       Feel free to <span tw="text-primary-500">get in touch</span>
//       <wbr /> with us.
//     </>
//   ),
//   description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//   submitButtonText = "Send",
//   formAction = "#",
//   formMethod = "get",
//   textOnLeft = true,
// }) => {
//   // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

//   return (
//     <Container>
//       <TwoColumn>
//         <ImageColumn>
//           <Image imageSrc={EmailIllustrationSrc} />
//         </ImageColumn>
//         <TextColumn textOnLeft={textOnLeft}>
//           <TextContent>
//             {subheading && <Subheading>{subheading}</Subheading>}
//             <Heading>{heading}</Heading>
//             {description && <Description>{description}</Description>}
//             <Form action={formAction} method={formMethod}>
//               <Input type="text" name="name" placeholder="Inventor's Name" />
//               <Input type="email" name="email" placeholder="Email Address" />
//               <Input
//                 type="text"
//                 name="phone number"
//                 placeholder="Phone Number"
//               />
//               <Input
//                 type="text"
//                 name="company"
//                 placeholder="Company/Organization"
//               />
//               <Input
//                 type="text"
//                 name="service required"
//                 placeholder="What service do you require?"
//               />
//               <Input
//                 type="text"
//                 name="patent type"
//                 placeholder="What is the patent type?"
//               />
//               <label>
//                 Prior art search required?
//                 <Input type="radio" name="priorArtSearch" value="yes" /> Yes
//                 <Input type="radio" name="priorArtSearch" value="no" /> No
//               </label>
//               <label>
//                 Goal with this patent:
//                 <select name="goalWithPatent">
//                   <option value="commercialization">Commercialization</option>
//                   <option value="technology transfer">
//                     Technology Transfer
//                   </option>
//                   <option value="licensing">Licensing</option>
//                   <option value="any other">Any Other</option>
//                 </select>
//               </label>
//               <Textarea
//                 name="message"
//                 placeholder="Brief description of your patent."
//               />
//               <SubmitButton type="submit">{submitButtonText}</SubmitButton>
//             </Form>
//           </TextContent>
//         </TextColumn>
//       </TwoColumn>
//     </Container>
//   );
// };

// import React, { useState } from "react";
// import tw from "twin.macro";
// import styled from "styled-components";
// import {
//   SectionHeading,
//   Subheading as SubheadingBase,
// } from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import EmailIllustrationSrc from "images/email-illustration.svg";

// const Container = tw.div`relative`;
// const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
// const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
// const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
// const TextColumn = styled(Column)((props) => [
//   tw`md:w-7/12 mt-16 md:mt-0`,
//   props.textOnLeft
//     ? tw`md:mr-12 lg:mr-16 md:order-first`
//     : tw`md:ml-12 lg:ml-16 md:order-last`,
// ]);

// const Image = styled.div((props) => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded bg-contain bg-no-repeat bg-center h-full`,
// ]);
// const TextContent = tw.div`lg:py-8 text-center md:text-left`;

// const Subheading = tw(SubheadingBase)`text-center md:text-left`;
// const Heading = tw(
//   SectionHeading
// )`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
// const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

// const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
// const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
// const Textarea = styled(Input).attrs({ as: "textarea" })`
//   ${tw`h-24`}
// `;

// const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

// const FormGroup = styled.div`
//   ${tw`mt-6`}
// `;

// const Label = tw.label`font-medium text-sm text-gray-600 mb-2 block`;
// const RadioGroup = styled.div`
//   ${tw`flex items-center mt-2 space-x-4`}
// `;

// const Select = styled.select`
//   ${tw`mt-2 border-b-2 py-3 w-full focus:outline-none font-medium transition duration-300 hocus:border-primary-500`}
// `;

// export default ({
//   subheading = "Contact Us",
//   heading = (
//     <>
//       Feel free to <span tw="text-primary-500">get in touch</span>
//       <wbr /> with us.
//     </>
//   ),
//   description = "",
//   submitButtonText = "Send",
//   formAction = "#",
//   formMethod = "get",
//   textOnLeft = true,
// }) => {
//   const [goal, setGoal] = useState("");
//   const handleGoalChange = (e) => {
//     setGoal(e.target.value);
//   };

//   return (
//     <Container>
//       <TwoColumn>
//         <ImageColumn>
//           <Image imageSrc={EmailIllustrationSrc} />
//         </ImageColumn>
//         <TextColumn textOnLeft={textOnLeft}>
//           <TextContent>
//             {subheading && <Subheading>{subheading}</Subheading>}
//             <Heading>{heading}</Heading>
//             {description && <Description>{description}</Description>}
//             <Form action={formAction} method={formMethod}>
//               <Input type="text" name="name" placeholder="Inventor's Name" />
//               <Input type="email" name="email" placeholder="Email Address" />
//               <Input
//                 type="text"
//                 name="phone number"
//                 placeholder="Phone Number"
//               />
//               <Input
//                 type="text"
//                 name="company"
//                 placeholder="Company/Organization"
//               />
//               <Input
//                 type="text"
//                 name="service required"
//                 placeholder="What service do you require?"
//               />
//               <Input
//                 type="text"
//                 name="patent type"
//                 placeholder="What is the patent type?"
//               />

//               <FormGroup>
//                 <Label>Prior art search required?</Label>
//                 <RadioGroup>
//                   <label>
//                     <Input type="radio" name="priorArtSearch" value="yes" /> Yes
//                   </label>
//                   <label>
//                     <Input type="radio" name="priorArtSearch" value="no" /> No
//                   </label>
//                 </RadioGroup>
//               </FormGroup>

//               <FormGroup>
//                 <Label>Goal with this patent:</Label>
//                 <Select name="goalWithPatent" onChange={handleGoalChange}>
//                   <option value="commercialization">Commercialization</option>
//                   <option value="technology transfer">
//                     Technology Transfer
//                   </option>
//                   <option value="licensing">Licensing</option>
//                   <option value="any other">Any Other</option>
//                 </Select>
//                 {goal === "any other" && (
//                   <Input
//                     type="text"
//                     name="otherGoal"
//                     placeholder="Please specify your goal"
//                   />
//                 )}
//               </FormGroup>

//               <Textarea
//                 name="message"
//                 placeholder="Brief description of your patent."
//               />
//               <SubmitButton type="submit">{submitButtonText}</SubmitButton>
//             </Form>
//           </TextContent>
//         </TextColumn>
//       </TwoColumn>
//     </Container>
//   );
// };

import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import ContactUsFormOnly from "./ContactUsFormOnly";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const MapColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 pl-6 -mt-12`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const TextContent = tw.div` text-left md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-2xl sm:text-3xl lg:text-3xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

const FormGroup = styled.div`
  ${tw`mt-6`}
`;

const Label = tw.label`font-medium text-sm text-gray-600 mb-2 block`;
const RadioGroup = styled.div`
  ${tw`flex items-center mt-2 space-x-4`}
`;

const Select = styled.select`
  ${tw`mt-2 border-b-2 py-3 w-full focus:outline-none font-medium transition duration-300 hocus:border-primary-500`}
`;

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  const [goal, setGoal] = useState("");
  const handleGoalChange = (e) => {
    setGoal(e.target.value);
  };

  return (
    <Container>
      <TwoColumn>
        <MapColumn>
          {/* Replace the image with an iframe for Google Maps */}
          <iframe
            title="Google Maps"
            width="100%" // Make the width responsive to the parent container
            height="100%" // Adjust the height as needed
            frameBorder="0" // Remove the iframe border
            style={{ border: 0 }} // Additional styling to ensure no border
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1019.089573832882!2d77.16026445991032!3d28.519709202901147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ffea0237b5b%3A0xaad1873ba8e264d8!2sB1%20park!5e0!3m2!1sen!2sin!4v1731579560489!5m2!1sen!2sin"
            allowFullScreen
            loading="lazy" // Lazy loading to improve performance
            referrerPolicy="no-referrer-when-downgrade"
          />
        </MapColumn>

        <TextColumn textOnLeft={false}>
          <ContactUsFormOnly />
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
