import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import VisionImage from "../../images/vision.svg";
import MissonImage from "../../images/misson.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;

const Heading = tw(SectionHeading)`w-full text-3xl`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex flex-col items-center`}
`;

const CardHeading = styled.h2`
  ${tw`text-2xl font-bold text-center underline mb-4 mt-5`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-2 border-2 border-dashed border-primary-500 rounded-lg mt-4`}
  height: 450px; /* Set a fixed height for consistency */
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-16 h-16`}
    }
  }

  .textContainer {
    ${tw`text-center`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  const cards = [
    {
      imageSrc: VisionImage,
      title: "Our Vision",
      description:
        "Empowering innovators with a complete, strategic approach to patent protection, leveraging, and commercialization.",
    },
    {
      imageSrc: MissonImage,
      title: "Our Mission",
      description:
        "Our mission is to empower innovators by providing strategic, end-to-end guidance on their patent journey—from insightful landscape analysis and comprehensive protection to effective technology transfer and commercialization—unlocking the full market potential of their ideas and driving sustainable success.",
    },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        {/* <Heading>
          Our Professional <span tw="text-primary-500">Services</span>
        </Heading> */}
        {cards.map((card, i) => (
          <Column key={i}>
            <CardHeading>{card.title || "Fully Secure"}</CardHeading>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
