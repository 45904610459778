import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/dark.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/JustADescription";
import Features1 from "components/features/TwoColSingleFeatureWithStats2";
// import Features from "components/features/ThreeColWithSideImage.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

import PatentProtectionSvg from "images/patent-protection.svg";
import StrategyValuation from "images/strategy-valuation.svg";
import TechTransfer from "images/tech-transfer.svg";
import ScientificEditing from "images/scientific-editing.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Features1 />
      <MainFeature1
        subheading="Reliable"
        heading="Patent Protection and Strategy"
        description="We supports clients through each phase of patent management, from initial
idea assessments to patent filings and life-cycle support. Services include
prior art searches, drafting, and priority protection identification, tailored to
safeguard innovations legally and strategically."
        imageSrc={PatentProtectionSvg}
        buttonRounded={false}
      />
      <MainFeature1
        subheading="Secure"
        heading="Market Strategy and Patent Valuation"
        description="The firm aids in identifying potential investors or buyers by assessing patent
value, enabling clients to realize market potential and attract appropriate
partners."
        imageSrc={StrategyValuation}
        buttonRounded={false}
        textOnLeft={false}
      />
      <MainFeature1
        subheading="Secure"
        heading="Technology Transfer Guidance"
        description="We assists academic institutions and inventors in transferring technology to
the industry by preparing documentation and identifying suitable licensees or
buyers."
        imageSrc={TechTransfer}
        buttonRounded={false}
      />
      <MainFeature1
        subheading="Secure"
        heading="Scientific Editing"
        description="We also offers scientific editing services, enhancing the clarity and technical
precision of clients’ documents to meet high standards in scientific
communication."
        imageSrc={ScientificEditing}
        buttonRounded={false}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading></Subheading>}
        heading="Operating within India’s growing startup ecosystem, this firm is committed to
guiding emerging entrepreneurs and academic innovators in strategically
managing their Patent, positioning it as an essential asset for long-term
success in international markets."
        description=""
        // cards={[
        //   {
        //     imageSrc: SupportIconImage,
        //     title: "24/7 Support",
        //     description:
        //       "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
        //   },
        //   {
        //     imageSrc: ShieldIconImage,
        //     title: "Strong Teams",
        //     description:
        //       "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
        //   },
        //   {
        //     imageSrc: CustomerLoveIconImage,
        //     title: "Customer Satisfaction",
        //     description:
        //       "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport",
        //   },
        // ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
