import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Services from "../features/ServicesOnly.js";
import ContactUs from "../../components/forms/ContactUsFormOnly.js";

const Container = tw.div`flex flex-col md:flex-row max-w-screen-xl mx-auto -mt-12 md:-mt-8`;
const Column = styled.div`
  ${tw`w-full md:w-1/2 p-4 flex flex-col justify-start`}
`;

const ServicesContactLayout = () => {
  return (
    <Container>
      {/* Left Column - Services Component */}
      <Column>
        <Services heading="Our Services" />
      </Column>

      {/* Right Column - Contact Us Component */}
      <Column>
        <ContactUs
          subheading=""
          heading="Let's discuss your patent needs"
          description="Feel free to reach out with any inquiries or questions you have regarding our services"
          formAction="#"
          formMethod="post"
        />
      </Column>
    </Container>
  );
};

export default ServicesContactLayout;
