// import React from "react";
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
// import styled from "styled-components"; //eslint-disable-line
// import { css, keyframes } from "styled-components/macro"; //eslint-disable-line
// import Header from "components/headers/dark.js";
// import Footer from "components/footers/SimpleFiveColumn.js";

// import ModelQuestionPaperImage from "../images/modelquestionpaper.jpg";

// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//     transform: translateY(10px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const pulse = keyframes`
//   0% {
//     transform: scale(1);
//   }
//   50% {
//     transform: scale(1.05);
//   }
//   100% {
//     transform: scale(1);
//   }
// `;

// const SectionHeading = styled.h2`
//   ${tw`text-3xl sm:text-4xl font-black tracking-wide text-center mt-5`};
//   animation: ${fadeIn} 1s ease-out;
// `;

// const Subheading = tw.span`uppercase tracking-wider text-sm`;

// const ContentContainer = styled.div`
//   ${tw`max-w-screen-lg mx-auto p-5 text-center`}
//   animation: ${fadeIn} 1.2s ease-out;
// `;

// const HighlightedText = styled.span`
//   ${tw`font-bold text-xl`}

//   animation: ${pulse} 1.5s infinite;
// `;

// const ContentParagraph = styled.p`
//   ${tw`text-lg leading-relaxed mt-4`}
//   &:nth-child(odd) {
//     animation: ${fadeIn} 1s ease-out;
//   }
//   &:nth-child(even) {
//     animation: ${fadeIn} 1.5s ease-out;
//   }
// `;

// const ImageContainer = styled.div`
//   ${tw`flex justify-center mt-5`}
//   animation: ${fadeIn} 1s ease-out;
// `;

// export default () => {
//   return (
//     <AnimationRevealPage>
//       <Header />
//       <SectionHeading>
//         Model Paper for Patent Agent Examination - PAE 2025
//       </SectionHeading>
//       <ImageContainer>
//         <img
//           src={ModelQuestionPaperImage}
//           alt="PAE 2025 Model Question Paper"
//           tw="max-w-full rounded-lg shadow-lg"
//         />
//       </ImageContainer>
//       <ContentContainer>
//         <ContentParagraph>
//           <HighlightedText>
//             Get ready to prepare for your Patent Agent Exam with our exclusive
//             Model Question Paper with Descriptive Answer Key!
//           </HighlightedText>
//         </ContentParagraph>
//         <ContentParagraph>
//           Our team is working diligently to bring you the most comprehensive and
//           up-to-date model question paper with answers.
//         </ContentParagraph>
//         <ContentParagraph>
//           <HighlightedText>Stay tuned!</HighlightedText> <br />
//           <br />
//           Our Model Question Paper and Descriptive Answer Key for PAE 2025 will
//           be <HighlightedText>LIVE</HighlightedText> soon!
//         </ContentParagraph>
//         <ContentParagraph>
//           Check back in a few days to access the model question paper with
//           answers and kickstart your exam preparation!
//         </ContentParagraph>
//       </ContentContainer>
//       <Footer />
//     </AnimationRevealPage>
//   );
// };

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css, keyframes } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/dark.js";
import Footer from "components/footers/SimpleFiveColumn.js";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const SectionHeading = styled.h2`
  ${tw`text-3xl sm:text-4xl font-black tracking-wide text-center mt-5`};
  animation: ${fadeIn} 1s ease-out;
`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const ContentContainer = styled.div`
  ${tw`max-w-screen-lg mx-auto p-5 text-center`}
  animation: ${fadeIn} 1.2s ease-out;
`;

const HighlightedText = styled.span`
  ${tw`font-bold text-xl`}

  animation: ${pulse} 1.5s infinite;
`;

const ContentParagraph = styled.p`
  ${tw`text-lg leading-relaxed mt-4`};
  &:nth-child(odd) {
    animation: ${fadeIn} 1s ease-out;
  }
  &:nth-child(even) {
    animation: ${fadeIn} 1.5s ease-out;
  }
`;

const CallToActionLink = styled.a`
  ${tw`text-blue-500 hover:text-blue-700 underline font-bold`}
`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <SectionHeading>
        Ace the Patent Agent Exam (PAE-2025) with Confidence!
      </SectionHeading>
      <ContentContainer>
        <ContentParagraph>
          Introducing Our Model Question Paper for PAPER-I
          <br />
          Complete with a{" "}
          <HighlightedText>Descriptive Answer Key</HighlightedText>,
          meticulously crafted to ensure your success.
        </ContentParagraph>
        <ContentParagraph>
          ✅{" "}
          <HighlightedText>
            Aligned with the Latest Exam Pattern
          </HighlightedText>
        </ContentParagraph>
        <ContentParagraph>
          ✅ <HighlightedText>Detailed Explanations</HighlightedText>: Each
          multiple-choice answer is connected directly to the Patent Act for
          comprehensive understanding.
        </ContentParagraph>
        <ContentParagraph>
          ✅ <HighlightedText>Master Situational Questions</HighlightedText>:
          Gain clarity on ambiguous scenarios with expert analysis.
        </ContentParagraph>
        <ContentParagraph>
          Haven't Started Your Prep Yet? No Worries! This guide is your shortcut
          to success, offering insights and techniques to tackle the toughest
          questions effectively.
        </ContentParagraph>
        <ContentParagraph>Get it Now for Just Rs. 499/-</ContentParagraph>
        <ContentParagraph>
          Unlock your pathway to success by filling out the form below.
        </ContentParagraph>
        <ContentParagraph>
          <CallToActionLink href="/payment-page">
            Click Here to Access the Form
          </CallToActionLink>
        </ContentParagraph>
        <ContentParagraph>
          💡{" "}
          <HighlightedText>
            Smart Preparation, Guaranteed Results!
          </HighlightedText>
        </ContentParagraph>
      </ContentContainer>
      <Footer />
    </AnimationRevealPage>
  );
};
