import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import {
  SectionHeading as Heading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import IndrajeetTGB from "images/Testimonials/Indrajeet-TGP.jpg";
import HarshitNoccarc from "images/Testimonials/Harshit-Noccarc.jpg";
import SaritaBotlab from "images/Testimonials/sarita-botlab.png";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-justify max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-40 h-40 rounded-full`;
const Quote = tw.blockquote`mt-5 text-sm font-medium text-secondary-300  leading-loose`;
const CustomerName = styled.div`
  ${tw`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "",
  heading = "Testimonials",
  testimonials = [
    {
      imageSrc: IndrajeetTGB,
      quote:
        "The founder of the company, Dr. Smitha, made significant contributions to the journey of our company, TGP Bioplastics. Her timely suggestions, prompt responses, and disciplined documentation simplified our early challenges with understanding various policies and procedures, enabling us to achieve a large-scale manufacturing facility within the desired time frame.",
      customerName:
        "Mr. Indrajeet Chandrakant Nikam, Co-Founder, <br/>M/s TGP Bioplastics Pvt. Ltd.",
    },
    {
      imageSrc: SaritaBotlab,
      quote:
        "My interactions with Dr. Smitha have always been vibrant and positive, leaving a lasting impression. I am confident that her team is well-equipped to guide the client in effectively securing their intellectual property.",
      customerName:
        "Dr. Sarita Ahlawat, Co-Founder M/s Botlab Dynamics Pvt. Ltd.",
    },
    {
      imageSrc: HarshitNoccarc,
      quote:
        "The team Noccarc had a fantastic experience working with the founder of this firm, Dr. Smitha. She demonstrated seamless project management and coordination skills.",
      customerName:
        "Mr. Harshit Rathore,<br/>Co-Founder, <br/>M/s Noccarc Robotics Pvt. Ltd.",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Image src={testimonial.imageSrc} />
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName
                  dangerouslySetInnerHTML={{
                    __html: `- ${testimonial.customerName}`,
                  }}
                />
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
