import RoadTripImage from "./blogImages/image.png";

const blogs = [
  {
    id: "tips-on-how",
    isFeatured: true,
    postImageSrc: RoadTripImage,
    authorImageSrc:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
    title: "10 Essential Tips for Traveling Safely",
    authorName: "Charlotte Delos",
    authorProfile: "Travel Enthusiast",
    shortDescription:
      "Lorem ipsum dolor sit amet, consecteturious adipiscing elit...",
    description: `
          <p>Traveling is one of the most enriching experiences we can have, but it comes with its own set of challenges. Whether you're exploring bustling cities or remote destinations, safety should always be your top priority.</p>
          
          <p>To help you stay safe, here are 10 essential tips every traveler should follow:</p>
          
          <ul>
            <li>Research your destination thoroughly.</li>
            <li>Always carry photocopies of important documents.</li>
            <li>Invest in travel insurance.</li>
            <li>Trust your instincts and avoid oversharing on social media.</li>
          </ul>
          
          <h3>An Image to Inspire Your Wanderlust</h3>
          <img src="https://images.unsplash.com/photo-1573495612870-9f5a092e231d?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" alt="Travel Safety" />
    
          <h3>A Quick Travel Safety Video</h3>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/3Qy-LG2sMRM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          
          <p>Traveling is all about creating memories while staying cautious. Follow these tips to ensure your adventures are as safe as they are memorable!</p>
        `,
  },
  {
    id: "enjoying",
    isFeatured: true,
    postImageSrc: RoadTripImage,
    authorImageSrc:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
    title: "The Ultimate Guide to Enjoying Beach Vacations",
    authorName: "Adam Cuppy",
    authorProfile: "Lifestyle Blogger",
    shortDescription:
      "Lorem ipsum dolor sit amet, consecteturious adipiscing elit...",
    description: `
          <p>Traveling is one of the most enriching experiences we can have, but it comes with its own set of challenges. Whether you're exploring bustling cities or remote destinations, safety should always be your top priority.</p>
          
          <p>To help you stay safe, here are 10 essential tips every traveler should follow:</p>
          
          <ul>
            <li>Research your destination thoroughly.</li>
            <li>Always carry photocopies of important documents.</li>
            <li>Invest in travel insurance.</li>
            <li>Trust your instincts and avoid oversharing on social media.</li>
          </ul>
          
          <h3>An Image to Inspire Your Wanderlust</h3>
          <img src="https://images.unsplash.com/photo-1573495612870-9f5a092e231d?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" alt="Travel Safety" />
    
          <h3>A Quick Travel Safety Video</h3>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/3Qy-LG2sMRM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          
          <p>Traveling is all about creating memories while staying cautious. Follow these tips to ensure your adventures are as safe as they are memorable!</p>
        `,
  },
  {
    id: "getting-the-most-out-of-your-vacation",
    isFeatured: false,
    postImageSrc:
      "https://images.unsplash.com/photo-1552733407-5d5c46c3bb3b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&q=80",
    authorImageSrc: "https://randomuser.me/api/portraits/men/1.jpg", // Placeholder image for author
    title: "Getting the most out of your vacation",
    authorName: "Aaron Patterson",
    authorProfile: "Travel Blogger",
    shortDescription:
      "Learn how to make the most of your next vacation with these tips.",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique turpis urna, ut dictum odio varius a. Phasellus maximus libero urna, et tincidunt erat aliquam ac.`,
  },
  {
    id: "choosing-the-perfect-safaris-in-africa",
    isFeatured: false,
    postImageSrc:
      "https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&q=80",
    authorImageSrc: "https://randomuser.me/api/portraits/men/2.jpg", // Placeholder image for author
    title: "Choosing the perfect Safaris in Africa",
    authorName: "Sam Phipphen",
    authorProfile: "Adventure Enthusiast",
    shortDescription:
      "A guide to choosing the best safaris in Africa for adventure seekers.",
    description: `Quisque malesuada justo vitae sapien scelerisque, at sodales magna ultricies. Sed vitae dapibus erat. Donec eu urna nisl. Nam nec interdum felis, eget sodales dui.`,
  },
  {
    id: "hiking-during-the-monsoon-in-asia",
    isFeatured: false,
    postImageSrc:
      "https://images.unsplash.com/photo-1503220317375-aaad61436b1b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&q=80",
    authorImageSrc: "https://randomuser.me/api/portraits/men/3.jpg", // Placeholder image for author
    title: "Hiking during the monsoon in Asia",
    authorName: "Tony Hawk",
    authorProfile: "Outdoor Expert",
    shortDescription: "Tips for hiking in Asia during the monsoon season.",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur volutpat sem non leo cursus, eu dignissim ante feugiat. Fusce sagittis, nulla id vestibulum posuere, urna nunc malesuada est.`,
  },
  {
    id: "must-carry-items-while-travelling-to-thailand",
    isFeatured: false,
    postImageSrc:
      "https://images.unsplash.com/photo-1504609773096-104ff2c73ba4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&q=80",
    authorImageSrc: "https://randomuser.me/api/portraits/men/4.jpg", // Placeholder image for author
    title: "Must carry items while travelling to Thailand",
    authorName: "Himali Turn",
    authorProfile: "Travel Blogger",
    shortDescription: "Essential items to pack for a trip to Thailand.",
    description: `Donec vel tortor sit amet dui lacinia dignissim. Nulla facilisi. Aliquam auctor, magna vitae tempor consequat, eros ipsum sollicitudin neque, vel vestibulum odio arcu non enim.`,
  },
  {
    id: "an-extremely-funny-trip-to-the-swiss-alps",
    isFeatured: false,
    postImageSrc:
      "https://images.unsplash.com/photo-1546971587-02375cbbdade?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=641&q=80",
    authorImageSrc: "https://randomuser.me/api/portraits/men/5.jpg", // Placeholder image for author
    title: "An extremely funny trip to the Swiss Alps",
    authorName: "Naomi Watts",
    authorProfile: "Comedian Traveler",
    shortDescription:
      "Join Naomi for a hilarious journey through the Swiss Alps.",
    description: `Fusce porttitor, ante vitae fringilla rutrum, lorem sapien pellentesque eros, et venenatis lorem arcu ac justo. Nunc ut quam at ligula pretium lacinia non ut risus.`,
  },
];

export default blogs;
