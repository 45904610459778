// import React, { useState } from "react";
// import tw from "twin.macro";
// import styled from "styled-components";
// import {
//   SectionHeading,
//   Subheading as SubheadingBase,
// } from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

// const Container = tw.div`relative max-w-md mx-auto py-10`;
// const TextContent = tw.div`text-left md:text-left`;
// const Subheading = tw(SubheadingBase)`text-center md:text-left`;
// const Heading = tw(
//   SectionHeading
// )`font-black text-left text-3xl sm:text-4xl lg:text-3xl leading-tight`;
// const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
// const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col`;
// const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
// const Textarea = styled(Input).attrs({ as: "textarea" })`
//   ${tw`h-24`}
// `;
// const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

// const FormGroup = styled.div`
//   ${tw`mt-6`}
// `;

// const Label = tw.label`font-medium text-sm text-gray-600 mb-2 block`;
// const RadioGroup = styled.div`
//   ${tw`flex items-center mt-2 space-x-4`}
// `;

// const Select = styled.select`
//   ${tw`mt-2 border-b-2 py-3 w-full focus:outline-none font-medium transition duration-300 hocus:border-primary-500`}
// `;

// export default ({
//   subheading = "Contact Us",
//   heading = "Feel free to get in touch",
//   description = "",
//   submitButtonText = "Send",
//   formAction = "https://formspree.io/f/xeoqkvyy",
//   formMethod = "POST",
// }) => {
//   const [goal, setGoal] = useState("");
//   const handleGoalChange = (e) => setGoal(e.target.value);

//   return (
//     <Container>
//       <TextContent>
// {subheading && <Subheading>{subheading}</Subheading>}
// <Heading>{heading}</Heading>
// {description && <Description>{description}</Description>}
//         <Form action="https://formspree.io/f/xeoqkvyy" method="POST">
//           <Input type="text" name="name" placeholder="Inventor's Name" />
//           <Input type="email" name="email" placeholder="Email Address" />
//           <Input type="text" name="phone number" placeholder="Phone Number" />
//           <Input
//             type="text"
//             name="company"
//             placeholder="Company/Organization"
//           />
//           <Input
//             type="text"
//             name="service required"
//             placeholder="What service do you require?"
//           />
//           <Input
//             type="text"
//             name="patent type"
//             placeholder="What is the patent type?"
//           />

//           <Input
//             type="text"
//             name="priot art"
//             placeholder="Prior art search required? (Yes/No)"
//           />

//           <FormGroup>
//             <Label>Goal with this patent:</Label>
//             <Select name="goalWithPatent" onChange={handleGoalChange}>
//               <option value="commercialization">Commercialization</option>
//               <option value="technology transfer">Technology Transfer</option>
//               <option value="licensing">Licensing</option>
//               <option value="any other">Any Other</option>
//             </Select>
//             {goal === "any other" && (
//               <Input
//                 type="text"
//                 name="otherGoal"
//                 placeholder="Please specify your goal"
//               />
//             )}
//           </FormGroup>

//           <Textarea
//             name="message"
//             placeholder="Brief description of your patent."
//           />
//           <SubmitButton type="submit">{submitButtonText}</SubmitButton>
//         </Form>
//       </TextContent>
//     </Container>
//   );
// };

// import React from "react";
// import tw, { styled } from "twin.macro";

// const Form = tw.form`flex flex-col gap-2 bg-white p-6 rounded-[20px] font-sans`;
// const FlexColumn = tw.div`flex flex-col`;
// const Label = tw.label`text-black font-semibold`;
// const InputForm = tw.div`border border-[#ecedec] rounded-[10px] h-12 flex items-center pl-2 transition-all duration-200 focus-within:border-[#2d79f3]`;
// const Input = tw.input`ml-2 rounded-[10px] border-none w-[85%] h-full focus:outline-none`;
// const ButtonSubmit = tw.button`my-5 bg-black border-none text-white text-sm font-medium rounded-[10px] h-12 w-full cursor-pointer hover:bg-[#252727] transition-all`;
// const FlexRow = tw.div`flex flex-row items-center gap-2 justify-between`;
// const Span = tw.span`text-[#2d79f3] font-medium text-sm ml-1 cursor-pointer`;
// const Paragraph = tw.p`text-center text-black text-sm mt-2`;
// const Btn = tw.button`mt-3 w-full h-12 rounded-[10px] flex justify-center items-center font-medium gap-2 border border-[#ededef] bg-white cursor-pointer transition-all hover:border-[#2d79f3]`;

// const SignupForm = () => {
//   return (
//     <Form>
//       {/* Name */}
//       <FlexColumn>
//         <Label>Name</Label>
//       </FlexColumn>
//       <InputForm>
//         <svg
//           height="60"
//           viewBox="0 -9 32 32"
//           width="40"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <g id="Layer_3" data-name="Layer 3">
//             <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"></path>
//           </g>
//         </svg>
//         <Input type="text" placeholder="Enter your Name" />
//       </InputForm>

//       {/* Email */}
//       <FlexColumn>
//         <Label>Email</Label>
//       </FlexColumn>
//       <InputForm>
//         <svg
//           height="20"
//           viewBox="0 0 32 32"
//           width="20"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <g id="Layer_3" data-name="Layer 3">
//             <path d="..."></path>
//           </g>
//         </svg>
//         <Input type="text" placeholder="Enter your Email" />
//       </InputForm>

//       {/* Password */}
//       <FlexColumn>
//         <Label>Password</Label>
//       </FlexColumn>
//       <InputForm>
//         <svg
//           height="20"
//           viewBox="-64 0 512 512"
//           width="20"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path d="..."></path>
//         </svg>
//         <Input type="password" placeholder="Enter your Password" />
//       </InputForm>

//       <ButtonSubmit>Sign Up</ButtonSubmit>

//       <Paragraph>
//         Already have an account? <Span>Login</Span>
//       </Paragraph>

//       <FlexRow>
//         <Btn>
//           <svg
//             width="20"
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 512 512"
//           >
//             <path d="..."></path>
//           </svg>
//           Google
//         </Btn>
//       </FlexRow>
//     </Form>
//   );
// };

// export default SignupForm;

// import React, { useState } from "react";
// import tw, { styled } from "twin.macro";

// const Container = tw.div`flex justify-center items-center h-full w-full`;
// const Grid = tw.div`grid gap-8`;
// const Section = tw.section`bg-gradient-to-r from-red-500 to-purple-500 rounded-3xl`;
// const FormWrapper = tw.div`border-8 border-transparent rounded-xl bg-gray-200 shadow-xl p-8 m-2`;
// const Title = tw.h1`text-3xl font-bold text-center cursor-default text-gray-300 text-gray-900 mb-2`;
// const Form = tw.form`space-y-6`;
// const Label = tw.label`block mb-2 text-sm text-gray-300 `;
// const Input = tw.input`border p-3 shadow-md bg-indigo-700 text-gray-300 border-gray-700 border-gray-300 rounded-lg w-full focus:ring-2 focus:ring-blue-500 transition transform hover:scale-105 duration-300 text-sm`;
// const Select = tw.select`border p-3 shadow-md bg-indigo-700 text-gray-300 border-gray-700 border-gray-300 rounded-lg w-full  text-sm`;
// const Textarea = tw.textarea`border p-3 shadow-md bg-indigo-700 text-gray-300 border-gray-700 border-gray-300 rounded-lg w-full focus:ring-2 focus:ring-blue-500 transition transform hover:scale-105 duration-300 text-sm`;
// const Button = tw.button`w-full p-3 mt-4 text-white bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg hover:scale-105 transition transform duration-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500`;
// const SmallText = tw.p`text-sm text-center text-gray-300`;
// const FooterText = tw.p`mt-4 text-center text-sm text-gray-500`;
// const FooterLink = tw.a`text-blue-400 transition hover:underline`;

// const LoginForm = () => {
//   const [goal, setGoal] = useState("");

//   const handleGoalChange = (e) => setGoal(e.target.value);

//   return (
//     <Container>
//       <Grid>
//         <Section id="back-div">
//           <FormWrapper>
//             <Title>Submit Your Patent Details</Title>
//             <Form action="https://formspree.io/f/xeoqkvyy" method="POST">
//               <Input
//                 type="text"
//                 name="name"
//                 placeholder="Inventor's Name"
//                 required
//               />
//               <Input
//                 type="email"
//                 name="email"
//                 placeholder="Email Address"
//                 required
//               />
//               <Input
//                 type="text"
//                 name="phone number"
//                 placeholder="Phone Number"
//                 required
//               />
//               <Input
//                 type="text"
//                 name="company"
//                 placeholder="Company/Organization"
//               />
//               <Input
//                 type="text"
//                 name="service required"
//                 placeholder="What service do you require?"
//               />
//               <Input
//                 type="text"
//                 name="patent type"
//                 placeholder="What is the patent type?"
//               />
//               <Input
//                 type="text"
//                 name="prior art"
//                 placeholder="Prior art search required? (Yes/No)"
//               />
//               <div>
//                 <Label>Goal with this patent:</Label>
//                 <Select
//                   name="goalWithPatent"
//                   onChange={handleGoalChange}
//                   required
//                 >
//                   <option value="">Select a goal</option>
//                   <option value="commercialization">Commercialization</option>
//                   <option value="technology transfer">
//                     Technology Transfer
//                   </option>
//                   <option value="licensing">Licensing</option>
//                   <option value="any other">Any Other</option>
//                 </Select>
//                 {goal === "any other" && (
//                   <Input
//                     type="text"
//                     name="otherGoal"
//                     placeholder="Please specify your goal"
//                   />
//                 )}
//               </div>
//               <Textarea
//                 name="message"
//                 placeholder="Brief description of your patent."
//                 required
//               />
//               <Button type="submit">Submit</Button>
//             </Form>
//             {/* <SmallText>
//               By submitting, you agree to our{" "}
//               <FooterLink href="#">Terms</FooterLink> and{" "}
//               <FooterLink href="#">Privacy Policy</FooterLink>.
//             </SmallText> */}
//           </FormWrapper>
//         </Section>
//       </Grid>
//     </Container>
//   );
// };

// export default LoginForm;

// import React from "react";
// import tw, { styled } from "twin.macro";

// const Container = tw.div`flex justify-center items-center h-full w-full`;
// const Grid = tw.div`grid gap-8`;
// const Section = tw.section`bg-gradient-to-r from-blue-500 to-purple-500 rounded-3xl`;
// const FormWrapper = tw.div`border-8 border-transparent rounded-xl bg-white dark:bg-gray-900 shadow-xl p-8 m-2`;
// const Title = tw.h1`text-5xl font-bold text-center cursor-default dark:text-gray-300 text-gray-900`;
// const Form = tw.form`space-y-6`;
// const Label = tw.label`block mb-2 text-lg dark:text-gray-300`;
// const Input = tw.input`border p-3 shadow-md dark:bg-indigo-700 dark:text-gray-300 dark:border-gray-700 border-gray-300 rounded-lg w-full focus:ring-2 focus:ring-blue-500 transition transform hover:scale-105 duration-300`;
// const Link = tw.a`text-blue-400 text-sm transition hover:underline`;
// const Button = tw.button`w-full p-3 mt-4 text-white bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg hover:scale-105 transition transform duration-300 shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500`;
// const SmallText = tw.p`text-sm text-center dark:text-gray-300`;
// const IconButton = tw.button`p-2 rounded-lg hover:scale-105 transition transform duration-300 shadow-lg`;
// const Icon = tw.img`w-6 h-6`;
// const FooterText = tw.p`mt-4 text-center text-sm text-gray-500`;
// const FooterLink = tw.a`text-blue-400 transition hover:underline`;

// const LoginForm = () => (
//   <Container>
//     <Grid>
//       <Section id="back-div">
//         <FormWrapper>
//           <Title>Log in</Title>
//           <Form action="#" method="post">
//             <div>
//               <Label htmlFor="email">Email</Label>
//               <Input id="email" type="email" placeholder="Email" required />
//             </div>
//             <div>
//               <Label htmlFor="password">Password</Label>
//               <Input
//                 id="password"
//                 type="password"
//                 placeholder="Password"
//                 required
//               />
//             </div>
//             <Link href="#">Forget your password?</Link>
//             <Button type="submit">LOG IN</Button>
//           </Form>
//           <SmallText>
//             Don't have an account? <Link href="#">Sign Up</Link>
//           </SmallText>
//           <div id="third-party-auth" tw="flex justify-center gap-4 mt-5">
//             <IconButton>
//               <Icon
//                 src="https://ucarecdn.com/8f25a2ba-bdcf-4ff1-b596-088f330416ef/"
//                 alt="Google"
//                 loading="lazy"
//               />
//             </IconButton>
//             <IconButton>
//               <Icon
//                 src="https://ucarecdn.com/95eebb9c-85cf-4d12-942f-3c40d7044dc6/"
//                 alt="LinkedIn"
//                 loading="lazy"
//               />
//             </IconButton>
//             <IconButton>
//               <Icon
//                 src="https://ucarecdn.com/be5b0ffd-85e8-4639-83a6-5162dfa15a16/"
//                 alt="GitHub"
//                 loading="lazy"
//                 tw="dark:invert"
//               />
//             </IconButton>
//             <IconButton>
//               <Icon
//                 src="https://ucarecdn.com/6f56c0f1-c9c0-4d72-b44d-51a79ff38ea9/"
//                 alt="Facebook"
//                 loading="lazy"
//               />
//             </IconButton>
//             <IconButton>
//               <Icon
//                 src="https://ucarecdn.com/82d7ca0a-c380-44c4-ba24-658723e2ab07/"
//                 alt="Twitter"
//                 loading="lazy"
//               />
//             </IconButton>
//             <IconButton>
//               <Icon
//                 src="https://ucarecdn.com/3277d952-8e21-4aad-a2b7-d484dad531fb/"
//                 alt="Apple"
//                 loading="lazy"
//               />
//             </IconButton>
//           </div>
//           <FooterText>
//             By signing in, you agree to our{" "}
//             <FooterLink href="#">Terms</FooterLink> and{" "}
//             <FooterLink href="#">Privacy Policy</FooterLink>.
//           </FooterText>
//         </FormWrapper>
//       </Section>
//     </Grid>
//   </Container>
// );

// export default LoginForm;

// import React, { useState } from "react";
// import styled from "styled-components";

// const FormContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh;
//   background-color: #ffffff;
// `;

// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   background-color: rgba(154, 112, 45, 0.1);
//   padding: 60px 28px;
//   border-radius: 20px;
//   box-shadow: 6px 4px rgb(0, 2, 65);

//   max-width: auto;
//   width: 100%;
// `;

// const Heading = styled.span`
//   font-size: 25px;
//   text-align: center;
//   font-weight: 600;
// `;

// const Label = styled.span`
//   font-size: 16px;
// `;

// const Input = styled.input`
//   padding: 10px;
//   border-radius: 10px;
//   border: double 2px black;
//   font-size: 15px;
//   box-shadow: 2px 3px rgb(0, 2, 65);

//   width: 100%;
// `;

// const Textarea = styled.textarea`
//   ${Input};
//   height: 100px;
// `;

// const Select = styled.select`
//   ${Input};
//   padding: 10px 5px;
// `;

// const Button = styled.button`
//   padding: 10px;
//   border-radius: 10px;
//   margin-top: 20px;
//   background-color: rgb(241, 241, 130);
//   box-shadow: 2px 3px rgb(0, 2, 65);
//   color: black;
//   font-size: medium;
//   font-weight: 600;

//   transition: 400ms;
//   cursor: pointer;

//   &:hover {
//     background-color: rgb(235, 255, 59);
//   }
// `;

// export default ({
//   subheading = "Contact Us",
//   heading = "Feel free to get in touch",
//   description = "",
//   submitButtonText = "Send",
//   formAction = "https://formspree.io/f/xeoqkvyy",
//   formMethod = "POST",
// }) => {
//   const [goal, setGoal] = useState("");
//   const handleGoalChange = (e) => setGoal(e.target.value);

//   return (
//     <FormContainer>
//       <Form action={formAction} method={formMethod}>
//         <Heading>{heading}</Heading>
//         <Label>Inventor's Name</Label>
//         <Input type="text" name="name" placeholder="Enter Name" />
//         <Label>Email Address</Label>
//         <Input type="email" name="email" placeholder="Enter Email Address" />
//         <Label>Phone Number</Label>
//         <Input
//           type="text"
//           name="phone number"
//           placeholder="Enter Phone Number"
//         />
//         <Label>Company/Organization</Label>
//         <Input
//           type="text"
//           name="company"
//           placeholder="Enter Company/Organization"
//         />
//         <Label>What service do you require?</Label>
//         <Input
//           type="text"
//           name="service required"
//           placeholder="Enter Service Required"
//         />
//         <Label>What is the patent type?</Label>
//         <Input type="text" name="patent type" placeholder="Enter Patent Type" />
//         <Label>Prior art search required? (Yes/No)</Label>
//         <Input type="text" name="prior art" placeholder="Enter Yes or No" />
//         <Label>Goal with this patent</Label>
//         <Select name="goalWithPatent" onChange={handleGoalChange}>
//           <option value="commercialization">Commercialization</option>
//           <option value="technology transfer">Technology Transfer</option>
//           <option value="licensing">Licensing</option>
//           <option value="any other">Any Other</option>
//         </Select>
//         {goal === "any other" && (
//           <>
//             <Label>Please specify your goal</Label>
//             <Input type="text" name="otherGoal" placeholder="Enter Your Goal" />
//           </>
//         )}
//         <Label>Brief description of your patent</Label>
//         <Textarea name="message" placeholder="Enter Description" />
//         <Button type="submit">{submitButtonText}</Button>
//       </Form>
//     </FormContainer>
//   );
// };

import React, { useState } from "react";
import tw, { styled } from "twin.macro";

import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";

const Form = tw.form`flex flex-col font-sans  text-sm gap-4`;
const FlexColumn = tw.div`flex flex-col gap-1`;
const Label = tw.label`text-black font-semibold`;
const InputForm = tw.div`border border-[#ecedec] rounded-[10px] h-12 flex items-center px-3 transition-all duration-200 focus-within:border-[#2d79f3] transform hover:scale-105 duration-300`;
const Input = tw.input`ml-2 rounded-[10px] border-none w-full h-full focus:outline-none `;
const Textarea = tw.textarea`rounded-[10px] border border-[#ecedec] px-3 py-2 h-24 resize-none focus:outline-none focus:border-[#2d79f3] transition-all transform hover:scale-105 duration-300`;
const Select = tw.select`rounded-[10px] border border-[#ecedec] px-3 py-2 h-12 focus:outline-none focus:border-[#2d79f3] transition-all`;
const ButtonSubmit = tw.button`mt-2 bg-black border-none text-white text-sm font-medium rounded-[10px] h-12 w-full cursor-pointer hover:bg-[#252727] transition-all transform hover:scale-105 duration-300`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`font-black text-black text-left text-3xl sm:text-4xl lg:text-3xl leading-tight mb-4`;
const Description = tw.p`text-center md:text-center text-base font-medium leading-relaxed text-secondary-100`;
const Container = tw.div`relative max-w-xl mx-auto m-8 py-6 bg-white rounded-[20px] shadow-lg p-6`;

export default ({
  subheading = "",
  heading = "Feel free to get in touch",
  description = "",
  submitButtonText = "Send",
}) => {
  const [goal, setGoal] = useState("");

  return (
    <Container>
      <Heading>{heading}</Heading>
      <Form action="https://formspree.io/f/xeoqkvyy" method="POST">
        {/* Name */}
        <FlexColumn>
          <Label>Inventor's Name</Label>
          <InputForm>
            <Input type="text" name="name" placeholder="Enter your name" />
          </InputForm>
        </FlexColumn>

        {/* Email */}
        <FlexColumn>
          <Label>Email Address</Label>
          <InputForm>
            <Input type="email" name="email" placeholder="Enter your email" />
          </InputForm>
        </FlexColumn>

        {/* Phone */}
        <FlexColumn>
          <Label>Phone Number</Label>
          <InputForm>
            <Input
              type="text"
              name="phone number"
              placeholder="Enter your phone number"
            />
          </InputForm>
        </FlexColumn>

        {/* Company */}
        <FlexColumn>
          <Label>Company/Organization</Label>
          <InputForm>
            <Input
              type="text"
              name="company"
              placeholder="Enter your company or organization"
            />
          </InputForm>
        </FlexColumn>

        {/* Service Required */}
        <FlexColumn>
          <Label>What service do you require?</Label>
          <InputForm>
            <Input
              type="text"
              name="service required"
              placeholder="Specify the service"
            />
          </InputForm>
        </FlexColumn>

        {/* Patent Type */}
        <FlexColumn>
          <Label>What is the patent type?</Label>
          <InputForm>
            <Input
              type="text"
              name="patent type"
              placeholder="Enter patent type"
            />
          </InputForm>
        </FlexColumn>

        {/* Prior Art */}
        <FlexColumn>
          <Label>Prior art search required? (Yes/No)</Label>
          <InputForm>
            <Input type="text" name="prior art" placeholder="Yes or No" />
          </InputForm>
        </FlexColumn>

        {/* Goal */}
        <FlexColumn>
          <Label>Goal with this patent:</Label>
          <Select
            name="goalWithPatent"
            onChange={(e) => setGoal(e.target.value)}
          >
            <option value="commercialization">Commercialization</option>
            <option value="technology transfer">Technology Transfer</option>
            <option value="licensing">Licensing</option>
            <option value="any other">Any Other</option>
          </Select>
          {goal === "any other" && (
            <InputForm tw="mt-2">
              <Input
                type="text"
                name="otherGoal"
                placeholder="Please specify your goal"
              />
            </InputForm>
          )}
        </FlexColumn>

        {/* Description */}
        <FlexColumn>
          <Label>Brief description of your patent:</Label>
          <Textarea
            name="message"
            placeholder="Enter a brief description"
          ></Textarea>
        </FlexColumn>

        {/* Submit */}
        <ButtonSubmit type="submit">{submitButtonText}</ButtonSubmit>
        {/* {description && <Description>{description}</Description>} */}
      </Form>
    </Container>
  );
};
