import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const HeadingContainer = tw.div``;
const Heading = tw.h2`text-3xl font-bold text-center`;
const Description = tw.p`text-center text-gray-600 mt-2 mb-6`;

const Cards = styled.div`
  ${tw`flex flex-wrap justify-center max-w-4xl mx-auto`}
`;

const CardWrapper = styled.div`
  ${tw`w-full sm:w-1/2 pt-8 md:p-4`}
  ${({ isOdd, isLast }) => isOdd && isLast && tw`sm:w-full flex justify-center`}
`;
const Card = tw.div`relative flex flex-col items-center p-6 border border-gray-300 rounded-lg shadow-lg transition-transform transform hover:scale-105`;

const CardImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`w-48 h-48 bg-center bg-cover rounded-full mb-4 transition-opacity duration-300`}
`;

const CardContent = tw.div`flex flex-col items-center mt-4 transition-opacity duration-300`;
const Position = tw.span`uppercase font-semibold text-xs text-primary-500 mb-1 transition-opacity duration-300`;
const Name = tw.h3`text-xl font-medium text-gray-900 transition-opacity duration-300`;
const SmallDescription = tw.div`text-sm text-gray-900 mt-2 text-left transition-opacity duration-300`;

const Popup = styled.div`
  ${tw`absolute inset-0 p-4 text-[12px] md:text-sm text-black bg-black bg-opacity-5 rounded-lg opacity-0 transition-opacity duration-300 flex justify-center items-center text-justify`}
  visibility: hidden;
  pointer-events: none;
  z-index: 10;

  ul {
    ${tw`list-inside`}
  }
`;

const HoverCard = styled(Card)`
  &:hover
    ${CardImage},
    &:hover
    ${CardContent},
    &:hover
    ${Position},
    &:hover
    ${Name},
    &:hover
    ${SmallDescription} {
    ${tw`opacity-0`}
  }

  &:hover ${Popup} {
    ${tw`opacity-100`}
    visibility: visible;
  }
`;

const CardComponent = ({ cards, heading, description }) => {
  const isOdd = cards.length % 2 !== 0;

  return (
    <div className="container mx-auto px-4">
      <HeadingContainer>
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
      </HeadingContainer>
      <Cards>
        {cards.map((card, index) => (
          <CardWrapper
            key={index}
            isOdd={isOdd}
            isLast={index === cards.length - 1}
          >
            <HoverCard>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <Position>{card.position}</Position>
                <Name>{card.name}</Name>
                <SmallDescription
                  dangerouslySetInnerHTML={{ __html: card.smallDescription }}
                />
              </CardContent>
              <Popup
                dangerouslySetInnerHTML={{ __html: card.bigDescription }}
              />
            </HoverCard>
          </CardWrapper>
        ))}
      </Cards>
    </div>
  );
};

export default CardComponent;
