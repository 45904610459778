import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import Header from "components/headers/dark.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import QRCode from "../images/QR Code.jpg";

const TwoColumnContainer = styled.div`
  ${tw`flex flex-col md:flex-row max-w-screen-lg mx-auto p-5 mt-10`}
`;

const Column = styled.div`
  ${tw`flex-1 flex flex-col items-center p-5`}
`;

const QRCodeImage = styled.img`
  ${tw`max-w-full rounded-lg shadow-lg`}
`;

const BankDetails = styled.div`
  ${tw`text-lg leading-relaxed mt-4 text-center`}
`;

const Heading = styled.h3`
  ${tw`text-2xl font-bold mb-10 mt-2 text-center`}
`;

const BankDetailsHeading = styled.h4`
  ${tw`text-2xl font-bold mb-10 mt-10 text-center`}
`;

const IframeContainer = styled.div`
  ${tw`w-full h-auto`}
  iframe {
    ${tw`w-full rounded-lg`}
  }
`;

const SectionHeading = styled.h2`
  ${tw`text-3xl sm:text-4xl font-black tracking-wide text-center mt-5`};
`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <SectionHeading>
        Ace the Patent Agent Exam (PAE-2025) with Confidence!
      </SectionHeading>
      <Heading>Get it Now for Just Rs. 499/-</Heading>
      <TwoColumnContainer>
        {/* Column 1: QR Code and Bank Details */}
        <Column>
          {/* Heading for QR Code */}
          <Heading>You can pay us through any UPI</Heading>
          <QRCodeImage
            src={QRCode} // Replace with your QR code image path
            alt="QR Code for Payment"
          />

          {/* Heading for Bank Details */}
          <BankDetailsHeading>
            Or You can transfer through bank
          </BankDetailsHeading>
          <BankDetails>
            <p>
              <strong>Name:</strong> RAMSMITH & ASSOCIATES
            </p>
            <p>
              <strong>Bank:</strong> HDFC Bank
            </p>
            <p>
              <strong>Branch:</strong> Vasant Kunj, New Delhi
            </p>
            <p>
              <strong>A/c No:</strong> 50100268905112
            </p>
            <p>
              <strong>IFSC:</strong> HDFC0002024
            </p>
          </BankDetails>
        </Column>

        {/* Column 2: Embedded Google Form */}
        <Column>
          <IframeContainer>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdP1PVTZcPss4kVw4elbdu3i6Rcn1GOW58yjRMAQVYA_GLX4A/viewform?embedded=true"
              width="100%"
              height="1100"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
          </IframeContainer>
        </Column>
      </TwoColumnContainer>
      <Footer />
    </AnimationRevealPage>
  );
};
