// // import React from "react";
// // import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// // import tw from "twin.macro";
// // import styled from "styled-components"; //eslint-disable-line
// // import { css } from "styled-components/macro"; //eslint-disable-line
// // import Header from "components/headers/dark.js";
// // import Footer from "components/footers/SimpleFiveColumn";
// // import MainFeature1 from "components/features/TwoColWithButton.js";
// // // import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// // // import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// // import Features from "components/features/ThreeColSimple.js";
// // // import Features from "components/features/ThreeColWithSideImage.js";
// // import TeamCardGrid from "components/cards/ProfileAndDescription";

// // import SupportIconImage from "images/support-icon.svg";
// // import ShieldIconImage from "images/shield-icon.svg";
// // import CustomerLoveIconImage from "images/simple-icon.svg";

// // const PageWrapper = tw.div`min-h-screen text-white`; // Creates a black background and adjusts text color

// // const Subheading = tw.span`uppercase tracking-wider text-sm`;

// // export default () => {
// //   return (
// //     <PageWrapper>
// //       <AnimationRevealPage>
// //         <Header />
// //         <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} />
// //         <Footer />
// //       </AnimationRevealPage>
// //     </PageWrapper>
// //   );
// // };
// import React from "react";
// import TeamCard from "../components/cards/ProfileAndDescription";
// import SmitaImage from "../images/Team/1-Smitha Puthucheri.jpg";
// import AshwiniImage from "../images/Team/2-Ashwini Pai.jpg";
// import JyotsanaImage from "../images/Team/3-Jyotsna.jpg";
// import SaleeshImage from "../images/Team/4-Saleesh.jpg";
// import RajagopalImage from "../images/Team/5-Rajagopal Subrahmanian.jpg";
// import SumalathaImage from "../images/Team/6-Sumanlatha.jpg";
// import KiranImage from "../images/Team/7-Kiran Mohan Das.jpg";
// import AnanthuImage from "../images/Team/8-Ananthu C N.jpg";
// import { ReactComponent as TwitterIcon } from "../images/twitter-icon.svg";
// import { ReactComponent as LinkedinIcon } from "../images/linkedin-icon.svg";

// const cards = [
//   {
//     imageSrc: SmitaImage,
//     position: "Founder & Managing Director",
//     name: "Dr. Smitha Puthucheri",
//     description: `
//       Registered Patent Agent<br />
//       PhD in Materials Chemistry - Indian Institute of Technology Kanpur<br />
//       Ex-Senior Project Coordinator at Technology Development Board (Department of Science & Technology) GOI.<br />
//       Ex-Patent Trainee at Technology Information Forecasting and Assessment Council (Department of Science & Technology) GOI.<br /><br />

//       <strong>Experience:</strong>
//       <ul>
//         <li>20 years in Research and Development in Chemistry/Materials Chemistry/DRDO projects</li>
//         <li>5 years in Industrial Project Management</li>
//         <li>7 years in Patent-related works and Patent Law Firm</li>
//         <li>3 years in manuscript editing services</li>
//         <li>40+ Scientific publications in National/International Journals</li>
//         <li>2 Granted Patents</li>
//       </ul>
//     `,
//     links: [
//       { url: "https://twitter.com", icon: TwitterIcon },
//       { url: "https://linkedin.com", icon: LinkedinIcon },
//     ],
//   },
//   // Add other cards similarly
// ];

// const TeamCardsList = () => (
//   <div>
//     {cards.map((card, index) => (
//       <TeamCard
//         key={index}
//         imageSrc={card.imageSrc}
//         name={card.name}
//         position={card.position}
//         description={card.description}
//         links={card.links}
//       />
//     ))}
//   </div>
// );

// export default TeamCardsList;

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import Header from "components/headers/dark.js";
import Footer from "components/footers/SimpleFiveColumn";
import TeamCard from "../components/cards/ProfileAndDescription";
import SmitaImage from "../images/Team/1-Smitha Puthucheri.jpg";
import AshwiniImage from "../images/Team/2-Ashwini Pai.jpg";
import JyotsanaImage from "../images/Team/3-Jyotsna.jpg";
import SaleeshImage from "../images/Team/4-Saleesh.jpg";
import RajagopalImage from "../images/Team/5-Rajagopal Subrahmanian.jpg";
import SumalathaImage from "../images/Team/6-Sumanlatha.jpg";
import KiranImage from "../images/Team/7-Kiran Mohan Das.jpg";
import AnanthuImage from "../images/Team/8-Ananthu C N.jpg";
import IqraImage from "../images/Team/9 - Iqra Khan.jpg";
import { ReactComponent as TwitterIcon } from "../images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "../images/linkedin-icon.svg";

// CSS styling to center the cards
const PageWrapper = tw.div`min-h-screen text-white flex flex-col items-center`; // Centers the content vertically and horizontally
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const CardsContainer = tw.div`flex flex-wrap justify-center items-center gap-8 max-w-screen-lg`; // Centers cards in a responsive grid with a gap
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center mt-5`;
const cards = [
  {
    imageSrc: SmitaImage,
    position: "Founder & Managing Director",
    name: "Dr. Smitha Puthucheri",
    description: `
      Registered Patent Agent<br />
      PhD in Materials Chemistry - Indian Institute of Technology Kanpur<br />
      Ex-Senior Project Coordinator at Technology Development Board (Department of Science & Technology) GOI.<br />
      Ex-Patent Trainee at Technology Information Forecasting and Assessment Council (Department of Science & Technology) GOI.<br /><br />

      <strong>Experience:</strong>
      <ul>
        <li>20 years in Research and Development in Chemistry/Materials Chemistry/DRDO projects</li>
        <li>5 years in Industrial Project Management</li>
        <li>7 years in Patent-related works and Patent Law Firm</li>
        <li>3 years in manuscript editing services</li>
        <li>40+ Scientific publications in National/International Journals</li>
        <li>2 Granted Patents</li>
      </ul>
    `,
    links: [
      {
        url: "https://twitter.com",
        icon: TwitterIcon,
      },
      {
        url: "https://linkedin.com",
        icon: LinkedinIcon,
      },
    ],
  },
  {
    imageSrc: AshwiniImage,
    position: "Patent Manager-Startups",
    name: "Mrs. P. Ashwini Pai",
    description: `
  Registered Patent Agent<br />
  M. Pharm (Pharmaceutics) - Qualified GPAT<br />
  Ex-Incharge of Innovation and Incubation Center and Assistant Professor at Nitte College of Pharmaceutical Sciences, Bengaluru<br />
  Ex-Project Manager: Student Startup & Innovation Policy (SSIP) Cell, Gujarat Knowledge Society, Gandhinagar<br />
  Ex-Patent Trainee at Technology Information Forecasting and Assessment Council (Department of Science & Technology) GOI.<br /><br />

  <strong>Experience:</strong>
  <ul style="text-align: left;">
    <li>7 years in IPR Management</li>
    <li>5 years in Research and Development</li>
  </ul>
`,

    links: [
      {
        url: "https://twitter.com",
        icon: TwitterIcon,
      },
      {
        url: "https://linkedin.com",
        icon: LinkedinIcon,
      },
    ],
  },
  {
    imageSrc: JyotsanaImage,
    position: "Patent Attorney",
    name: "Mrs. Jyotsana Dhasmana",
    description: `
  Registered Patent Agent<br />
  B.Tech in Biotechnology, Masters in Food Technology, Pursuing LLB<br />
  Ex-Patent Trainee at Technology Information Forecasting and Assessment Council (Department of Science & Technology) GOI.<br /><br />

  <strong>Experience:</strong>
  <ul style="text-align: left;">
    <li>7 years with Patent Law firms</li>
    <li>5 years in Patent Prosecution</li>
    <li>Experience in handling USPTO & German Patents</li>
  </ul>
`,

    links: [
      {
        url: "https://twitter.com",
        icon: TwitterIcon,
      },
      {
        url: "https://linkedin.com",
        icon: LinkedinIcon,
      },
    ],
  },
  {
    imageSrc: SaleeshImage,
    position: "Scientific Manager-Sciences",
    name: "Dr. Saleesh Kumar N. S",
    description: `
  Ph.D. in Organic Chemistry from CSIR-NIIST Thiruvananthapuram<br />
  Ex-Postdoctoral Fellow of European Union Marie Curie International Incoming Fellowship (EU-MC-IIF)<br /><br />

  <strong>Experience:</strong>
  <ul style="text-align: left;">
    <li>20 years in Research and Development</li>
    <li>Scientific Manuscript preparations</li>
    <li>22+ Publications in National/International Journals</li>
  </ul>
`,

    links: [
      {
        url: "https://twitter.com",
        icon: TwitterIcon,
      },
      {
        url: "https://linkedin.com",
        icon: LinkedinIcon,
      },
    ],
  },
  {
    imageSrc: RajagopalImage,
    position: "Scientific Manager-Engineering",
    name: "Dr. Rajagopal Subrahmaniam",
    description: `
  BE- Mechanical Engineering, PhD from University of Stuttgart - Germany<br />
  MBA holder in Technology Management<br />
  Freelance consultant for ISO certification<br />
  Ex-Patent Engineer for a private firm in Chennai<br /><br />

  <strong>Experience:</strong>
  <ul style="text-align: left;">
    <li>20 years of Research & Development</li>
    <li>7 years of IPR Management</li>
  </ul>
`,

    links: [
      {
        url: "https://twitter.com",
        icon: TwitterIcon,
      },
      {
        url: "https://linkedin.com",
        icon: LinkedinIcon,
      },
    ],
  },
  {
    imageSrc: SumalathaImage,
    position: "Patent Manager-Technology Transfer",
    name: "Dr. Sumalatha Bonthula",
    description: `
  PhD in Analytical Chemistry<br />
  Ex-Technology Transfer coordinator at NRDC Vishakapattanam<br />
  Ex-Patent Trainee at Technology Information Forecasting and Assessment Council (Department of Science & Technology) GOI.<br /><br />

  <strong>Experience:</strong>
  <ul style="text-align: left;">
    <li>15 years in Research & Development</li>
    <li>7 years in Patent and Technology Transfer field</li>
    <li>Preparation of Technology Transfer MoA/MoU for Universities/Institutes/Government Organizations</li>
    <li>Preparation of Tripartite Agreement, Assignment deed, pre-technical notes for Technology Assessment and Evaluation</li>
  </ul>
`,

    links: [
      {
        url: "https://twitter.com",
        icon: TwitterIcon,
      },
      {
        url: "https://linkedin.com",
        icon: LinkedinIcon,
      },
    ],
  },
  //   {
  //     imageSrc: IqraImage,
  //     position: "Digital Marketing Consultant",
  //     name: "Ms.Iqra Khan",
  //     description: `
  //   Ms. Iqra Khan is a digital marketing consultant with 8+ years of experience<br />
  //   Specializing in strategy and execution across branding, PR, and digital campaigns<br />
  //   She has worked with top brands like Google and Accenture driving measurable growth through targeted, data-driven strategies.<br />
  //   Known for turning insights into action, Iqra excels in SEO, content, and social media, helping businesses thrive in the digital landscape.<br />
  // `,

  //     links: [
  //       {
  //         url: "https://twitter.com",
  //         icon: TwitterIcon,
  //       },
  //       {
  //         url: "https://linkedin.com",
  //         icon: LinkedinIcon,
  //       },
  //     ],
  //   },
  {
    imageSrc: KiranImage,
    position: "Accounts Manager",
    name: "Mr. Kiran M",
    description:
      " Bachelor's Degree in Commerce <br /> 7  years of experience in Accounts Management",
    links: [
      {
        url: "https://twitter.com",
        icon: TwitterIcon,
      },
      {
        url: "https://linkedin.com",
        icon: LinkedinIcon,
      },
    ],
  },
  {
    imageSrc: AnanthuImage,
    position: "Website Manager",
    name: "Mr. Ananthu C N",
    description:
      " Diploma in Animation & Multimedia <br /> 3  years of experience in Network management ",
    links: [
      {
        url: "https://twitter.com",
        icon: TwitterIcon,
      },
      {
        url: "https://linkedin.com",
        icon: LinkedinIcon,
      },
    ],
  },
];

const TeamCardsList = () => (
  <CardsContainer>
    {cards.map((card, index) => (
      <TeamCard
        key={index}
        imageSrc={card.imageSrc}
        name={card.name}
        position={card.position}
        description={card.description}
        links={card.links}
      />
    ))}
  </CardsContainer>
);

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <SectionHeading>Meet the Team</SectionHeading>
      <PageWrapper>
        <Subheading>Our Team</Subheading>
        <TeamCardsList />
      </PageWrapper>
      <Footer />
    </AnimationRevealPage>
  );
};
