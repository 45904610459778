import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/dark.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center mt-5`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <SectionHeading>Contact Us</SectionHeading>
      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  );
};
