import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";
import fullCoverImg1 from "./1.png";
import fullCoverImg2 from "./2.png";
import fullCoverImg3 from "./3.png";
import fullCoverImg4 from "./4.png";
import fullCoverImg5 from "./5.png";
import fullCoverImg6 from "./6.png";

import mobilefullCoverImg1 from "./M1.png";
import mobilefullCoverImg2 from "./M2.png";
import mobilefullCoverImg3 from "./M3.png";
import mobilefullCoverImg4 from "./M4.png";
import mobilefullCoverImg5 from "./M5.png";
import mobilefullCoverImg6 from "./M6.png";

import Header from "../headers/light.js";

// Keyframes for fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
`;

// Styled Components
const AnimatedContainer = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url(${(props) => props.bgImage});
  animation: ${fadeIn} 1.5s ease-in-out;
  @media (max-width: 768px) {
    background-image: url(${(props) => props.bgImageMobile});
  }
`;

const Heading = tw.h1`font-bold text-5xl xl:text-[100px] text-gray-100 leading-tight font-Tangerine`;
const HeroContainer = tw.div`z-20 relative pt-8`;
const FrontPage = tw.div`flex items-center justify-center`;
const CenteredContentContainer = tw.div`flex flex-col items-center text-center mx-auto max-w-5xl px-4 lg:px-0 space-y-12`;

// Arrow Button Style
const ArrowButton = styled.button`
  ${tw`absolute top-1/2 transform -translate-y-1/2 text-white bg-opacity-50 hover:bg-opacity-75 p-2 rounded-full mt-56`}
  ${({ position }) => (position === "left" ? tw`left-0` : tw`right-0`)}
`;

const backgroundImages = [
  fullCoverImg1,
  fullCoverImg2,
  fullCoverImg3,
  fullCoverImg4,
  fullCoverImg5,
  fullCoverImg6,
];
const mobilebackgroundImages = [
  mobilefullCoverImg1,
  mobilefullCoverImg2,
  mobilefullCoverImg3,
  mobilefullCoverImg4,
  mobilefullCoverImg5,
  mobilefullCoverImg6,
];

export default ({
  roundedHeaderButton,
  scrollToSection,
  homeRef,
  servicesRef,
  teamMembersRef,
  contactUsRef,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % backgroundImages.length
      );
    }, 8000); // Change every 6 seconds
    return () => clearInterval(interval);
  }, []);

  const nextImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % backgroundImages.length
    );
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + backgroundImages.length) % backgroundImages.length
    );
  };

  return (
    <div>
      <AnimatedContainer
        key={currentImageIndex} // Adding key to re-render on image change
        bgImage={backgroundImages[currentImageIndex]}
        bgImageMobile={mobilebackgroundImages[currentImageIndex]}
      >
        <HeroContainer>
          <Header
            roundedHeaderButton={roundedHeaderButton}
            scrollToSection={scrollToSection}
            homeRef={homeRef}
            servicesRef={servicesRef}
            teamMembersRef={teamMembersRef}
            contactUsRef={contactUsRef}
          />
          <CenteredContentContainer>
            <FrontPage>
              <Heading>
                RamSmith & Associates
                <span tw="text-primary-500"></span>
              </Heading>
            </FrontPage>
          </CenteredContentContainer>
          <ArrowButton position="left" onClick={prevImage}>
            {"<"}
          </ArrowButton>
          <ArrowButton position="right" onClick={nextImage}>
            {">"}
          </ArrowButton>
        </HeroContainer>
      </AnimatedContainer>
    </div>
  );
};
