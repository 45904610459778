import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useParams, Link } from "react-router-dom";
import allBlogsData from "../components/blogs/BlogsData";
import Header from "components/headers/dark.js";
import Footer from "components/footers/SimpleFiveColumn.js";

// Container for the single blog page
const Container = tw.div`max-w-screen-xl mx-auto px-4 lg:px-8 py-16`;

// Header Section
const HeaderSection = tw.div`mb-12`;
const Title = tw.h1`text-3xl lg:text-5xl font-bold leading-snug text-left`;
const AuthorInfo = tw.div`flex items-center mt-6`;
const AuthorImage = tw.img`w-12 h-12 rounded-full`;
const AuthorDetails = tw.div`ml-4`;
const AuthorName = tw.h6`text-lg font-semibold`;
const AuthorProfile = tw.p`text-sm text-secondary-100`;

const BackButton = tw(
  Link
)`text-primary-500 hover:text-primary-700 transition duration-300 font-semibold mb-8 mt-10 inline-block`;
// Main Content Section
const MainContent = tw.div`flex flex-col lg:flex-row lg:space-x-16`;
const BlogContent = tw.div`lg:w-2/3 leading-loose text-secondary-800 text-lg`;

// Blog Image
const BlogImage = styled(motion.div)((props) => [
  `background-image: url("${props.$imageSrc}");`,
  tw`h-96 bg-cover bg-center rounded mb-8`,
]);

// Sidebar Section for Related Posts
const Sidebar = styled.div`
  ${tw`lg:w-1/3 mt-12 lg:mt-0`}
  & > h2 {
    ${tw`text-xl font-bold mb-6`}
  }
`;

// Related Posts
const RelatedPostsContainer = tw.div`mt-6 flex flex-col space-y-6`;
// const RelatedPost = tw.a`flex items-center transition duration-300 hover:opacity-75`;
// const RelatedPostImage = styled.div((props) => [
//   `background-image: url("${props.$imageSrc}");`,
//   tw`w-20 h-20 bg-cover bg-center rounded`,
// ]);
// const RelatedPostDetails = tw.div`ml-4`;
// const RelatedPostTitle = tw.h6`text-base font-bold leading-snug`;
// const RelatedPostAuthor = tw.p`text-sm text-secondary-100 mt-1`;

const RelatedPost = tw.a`flex flex-col items-center text-center transition duration-300 hover:opacity-75`;
const RelatedPostImage = styled.div((props) => [
  `background-image: url("${props.$imageSrc}");`,
  tw`w-full h-40 bg-cover bg-center rounded mb-4`,
]);
const RelatedPostTitle = tw.h6`text-lg font-bold leading-snug mt-2`;
const RelatedPostAuthor = tw.p`text-sm text-secondary-100 mt-1`;

export default () => {
  const blogId = useParams().id;

  const blogs = allBlogsData;

  const blogData = blogs.find((blog) => blog.id === blogId);

  if (!blogData) {
    return (
      <Container>
        <HeaderSection>
          <Title>Blog not found</Title>
        </HeaderSection>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Header />
        <BackButton to="/blogs">← Back to Blogs</BackButton>
        {/* Header Section */}
        <HeaderSection>
          <Title>{blogData.title}</Title>
          <AuthorInfo>
            <AuthorImage src={blogData.authorImageSrc} />
            <AuthorDetails>
              <AuthorName>{blogData.authorName}</AuthorName>
              <AuthorProfile>{blogData.authorProfile}</AuthorProfile>
            </AuthorDetails>
          </AuthorInfo>
        </HeaderSection>

        {/* Main Content Section */}
        <MainContent>
          {/* Blog Content */}
          <div tw="flex-1">
            <BlogImage $imageSrc={blogData.postImageSrc} />
            <BlogContent
              dangerouslySetInnerHTML={{ __html: blogData.description }}
            />
          </div>

          {/* Sidebar */}
          <Sidebar>
            <h2>Related Posts</h2>
            <RelatedPostsContainer>
              {blogs
                .filter((relatedBlog) => relatedBlog.id !== blogData.id)
                .slice(0, 3) // Display up to 3 related posts
                .map((relatedBlog) => (
                  <RelatedPost
                    key={relatedBlog.id}
                    href={`/blogs/${relatedBlog.id}`}
                  >
                    <RelatedPostImage $imageSrc={relatedBlog.postImageSrc} />
                    <RelatedPostTitle>{relatedBlog.title}</RelatedPostTitle>
                    <RelatedPostAuthor>
                      By {relatedBlog.authorName}
                    </RelatedPostAuthor>
                  </RelatedPost>
                ))}
            </RelatedPostsContainer>
          </Sidebar>
        </MainContent>
      </Container>
      <Footer />
    </>
  );
};
